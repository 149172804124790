<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="dashboard">
    <div class="dashboard-statistical">
      <h3 class="title">{{ $t('exchange.statistical') }}</h3>
      <p class="sub-title"><img src="~@/assets/images/icons/warning.png" alt=""> {{ $t('dashboard.sub_title') }}</p>
      <b-row>
        <b-col md="6" lg="6" xl="6">
          <div class="statistical-item chart">
            <div class="direct">
              <P @click="onGoToCrashGame">Happy
                <img src="~@/assets/images/icons/icon-next-dark.png" alt="" />
              </P>
            </div>
            <b-row>
              <b-col sm="7" md="7" lg="7" xl="7">
                <div id="chart">
                  <apexchart type="donut" :options="chartOptions" :series="series" width="400px">
                  </apexchart>
                </div>
              </b-col>
              <b-col sm="5" md="5" lg="5" xl="5">
                <div class="statistic-info">
                  <p>{{ $t('dashboard.successRate') }}</p>
                  <p class="statistic-info-percent">
                    {{ winrate.toFixed(2) }}%
                  </p>
                  <hr />
                  <div class="line"></div>
                  <p>{{ $t('expert.Total') }} Wow</p>
                  <p class="statistic-info-percent">
                    {{ TruncateToDecimals2(Statistic.total_bet || 0, '', 2) }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="6" lg="6" xl="6" class="d-flex justify-content-between flex-column">
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="statistical-item">
                <div class="direct">
                  <P @click="onGoToCrashGame">Happy
                    <img src="~@/assets/images/icons/icon-next-dark.png" alt="" />
                  </P>
                </div>
                <div class="d-flex justify-content-start align-items-center h-auto">
                  <img src="~@/assets/images/mockup/net-profit.png" alt="" class="img-item-info" />
                  <div class="statistical-item-info">
                    <p>{{ $t("affiliate.totalProfit") }}</p>
                    <P class="amount mb-0">$
                      {{ TruncateToDecimals2(+Member.totalProfit, "", 2) }}
                    </P>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="statistical-item">
                <div class="direct">
                  <P @click="onGoToAffiliate">Affiliate
                    <img src="~@/assets/images/icons/icon-next-dark.png" alt="" />
                  </P>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                  <img src="~@/assets/images/mockup/total-income.png" alt="" class="img-item-info" />
                  <div class="statistical-item-info">
                    <p>{{ $t('dashboard.total_income') }}</p>
                    <P class="amount mb-0">${{
                      TruncateToDecimals2(Statistic.total_income || 0, '', 2)
                    }}</P>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="12">
              <div class="statistical-item member">
                <div class="statistical-member">
                  <div class="statistical-member-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="box-member">
                        <h3>{{ $t('affiliate.numberMember') }}</h3>
                        <hr />
                        <p>{{ $t('affiliate.totalMember') }}</p>
                        <p>
                          <span class="amount">{{ Member.totalMember }}</span>
                        </p>
                        <p>
                          {{ $t('affiliate.activeDownline') }}:
                          <span class="amount">{{ Member.totalDownLineActive }}</span>
                        </p>
                      </div>
                      <div class="box-qr">
                        <img :src="`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${this.refLink}&choe=UTF-8`"
                          alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="statistical-member-item">
                    <div class="reflink">
                      <h3>Ref Link</h3>
                      <b-form-input :value="refLink" readonly class="custom-input"></b-form-input>
                      <b-button class="btnNoStyle copy-ref" v-clipboard:copy="refLink" v-clipboard:success="onCopy"
                        v-clipboard:error="onError">{{ $t('agency.copy') }}</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="dashboard-history">
      <div class="dashboard-history-header">
        <h3 class="title mb-0">{{ $t('exchange.history') }}</h3>
        <div class="group-search" v-if="false">
          <b-form-datepicker variant="primary" id="datepicker-sm-2" size="sm" locale="en" v-model="to" today-button
            reset-button close-button class="mb-0 customer-date-picker text-white custom-input mr-3"
            placeholder="yyyy/mm/dd" :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"></b-form-datepicker>
          <b-form-datepicker variant="primary" id="datepicker-sm-2" size="sm" locale="en" v-model="from" today-button
            reset-button close-button class="mb-0 customer-date-picker text-white custom-input" placeholder="yyyy/mm/dd"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"></b-form-datepicker>
        </div>
      </div>
      <div class="table-responsive historyTable" v-if="!showTableMobile">
        <div class="tabs-wrapper">
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBet' ? 'active' : ''"
            @click="setTab('userBet')">
            {{ $t('dashboard.myWowDemo') }}
          </button>
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBetMain' ? 'active' : ''"
            @click="setTab('userBetMain')">
            {{ $t('dashboard.myWowBS') }}
          </button>
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBetStaking' ? 'active' : ''"
            @click="setTab('userBetStaking')">
            {{ $t('dashboard.myWowStaking') }}
          </button>
        </div>
        <div class="myTable">
          <b-table striped hover :items="HistoryUserShow" :fields="fieldsHistoryUser" show-empty responsive>
            <template #empty>
              <h4 class="text-center my-3">
                {{ $t('support.no_data_available_in_table') }}
              </h4>
            </template>

            <template #cell(payout)="data">
              <p class="mb-0 text-betting font-weight-bold" :class="+data.item.payout >= 10
                  ? 'text-warning'
                  : +data.item.payout >= 2
                    ? 'text-success'
                    : 'text-danger'
                ">
                {{ TruncateToDecimals2(data.item.payout || 0, '', 2) }}x
              </p>
            </template>

            <template #cell(amountBet)="data">
              <p class="mb-0 text-betting font-weight-bold">
                <img v-if="data.item.currency === 'BS'" src="@/assets/images/currency/bs.png" class="currency-icon" />
                <img v-if="data.item.currency === 'SBS'" src="@/assets/images/currency/sbs.png" class="currency-icon" />
                {{ TruncateToDecimals2(data.item.amountBet || 0, '', 2) }}
              </p>
            </template>

            <template #cell(amountWin)="data">
              <p class="mb-0 text-betting font-weight-bold">
                <img v-if="data.item.currency === 'BS'" src="@/assets/images/currency/bs.png" class="currency-icon" />
                <img v-if="data.item.currency === 'SBS'" src="@/assets/images/currency/sbs.png" class="currency-icon" />
                {{ TruncateToDecimals2(data.item.amountWin || 0, '', 2) }}
              </p>
            </template>

            <template #cell(timeBet)="data">
              {{ getDateTime(data.item.timeBet) }}
            </template>

            <template #cell(profit)="data">
              <template v-if="data.item.status === 'SUCCESS'">
                <p class="text-win mb-0 font-weight-bold">
                  {{
                    TruncateToDecimals2(
                      data.item.amountWin - data.item.amountBet || 0,
                      '',
                      2
                    )
                  }}
                </p>
              </template>
              <template v-else>
                <p class="text-lose mb-0 font-weight-bold">
                  -
                  {{ TruncateToDecimals2(data.item.amountBet || 0, '', 2) }}
                </p>
              </template>
            </template>
          </b-table>
        </div>
      </div>
      <div class="table-mobile" v-else>
        <div class="tabs-wrapper">
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBet' ? 'active' : ''"
            @click="setTab('userBet')">
            {{ $t('dashboard.myWowDemo') }}
          </button>
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBetMain' ? 'active' : ''"
            @click="setTab('userBetMain')">
            {{ $t('dashboard.myWowBS') }}
          </button>
          <button class="botan gradient-border withBG withBlur" :class="CurrentTab === 'userBetStaking' ? 'active' : ''"
            @click="setTab('userBetStaking')">
            {{ $t('dashboard.myWowStaking') }}
          </button>
        </div>
        <template v-if="HistoryUserShow && HistoryUserShow.length > 0">
          <div v-for="(history, idx) in HistoryUserShow" :key="'profitHistory: ' + idx" class="w-100" role="tablist">
            <b-button v-b-toggle="`profitHistory-${idx}`" class="btnNoStyle header" role="tab">{{ history.comment
            }}</b-button>
            <b-collapse :id="`profitHistory-${idx}`" class="mt-2" accordion="my-accordion" role="tabpanel">
              <ul>
                <li>
                  Wow Id: <span>{{ history.id }}</span>
                </li>
                <li>
                  Wow:
                  <span>{{
                    TruncateToDecimals2(+history.amountBet, '', 2)
                  }}</span>
                </li>
                <li>
                  {{ $t('dashboard.payout') }}:
                  <span>{{ TruncateToDecimals2(+history.payout, '', 2) }}x</span>
                </li>
                <li>
                  Wow {{ $t('dashboard.success') }}:
                  <span>{{
                    TruncateToDecimals2(+history.amountWin, '', 2)
                  }}</span>
                </li>
                <li>
                  {{ $t('exchange.profit') }}:
                  <span>{{
                    TruncateToDecimals2(
                      history.amountWin - history.amountBet || 0,
                      '',
                      2
                    )
                  }}</span>
                </li>
                <li>
                  {{ $t('wallet.comment') }}:
                  <span>{{ history.comment }}</span>
                </li>
                <li>
                  {{ $t('exchange.time') }}:
                  <span>{{ getDateTime(history.timeBet) }}</span>
                </li>
              </ul>
            </b-collapse>
          </div>
        </template>
        <p v-else class="text-center">
          {{ $t('support.no_data_available_in_table') }}
        </p>
      </div>
      <div class="d-flex justify-content-end w-100">
        <Paginate :totalPage="TotalPageUser" :perPage="page" @current="onChangePageUser" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/Shared/Pagination.vue';

export default {
  name: 'PrivateDashboardViewPage',
  components: {
    Paginate,
  },
  data() {
    return {
      widthL: 0,
      showTableMobile: false,
      to: '',
      from: '',
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      Statistic: 'dashboard/Statistical',
      Member: 'member/Statistic',
      UserHistory: 'userinfo/UserHistory',
      CurrentTab: 'socket/CurrentTab',
      UserInfo: 'userinfo/UserInfo',
    }),

    chartOptions() {
      return {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#ffffff', '#ffffff'], // Set the colors of the labels
          },
          fontSize: '18px',
        },
        labels: [this.$t('expert.Total') + ' Wow ' + this.$t('dashboard.success'), this.$t('expert.Total') + ' Wow ' + this.$t('dashboard.fail')],
        colors: ['#ffb906', '#560BAD'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                fontSize: '14px',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              legend: {
                fontSize: '14px',
              },
            },
          },
          {
            breakpoint: 376,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom', // Set the position to 'bottom'
              },
            },
          },
        ],
      };
    },

    series() {
      return [this.Statistic.total_bet_win, this.Statistic.total_bet_lose];
    },

    winrate() {
      if (this.Statistic.total_bet === 0) return 0;
      return (this.Statistic.total_bet_win * 100) / this.Statistic.total_bet;
    },

    HistoryBetUser() {
      if (this.UserHistory) {
        switch (this.CurrentTab) {
          case 'userBet':
            return this.UserHistory.user_history;
          case 'userBetMain':
            return this.UserHistory.user_history_main;
          case 'userBetStaking':
            return this.UserHistory.user_history_staking;
        }
      }
      return {};
    },

    TotalPageUser() {
      let totalPage = 1;
      const length =
        this.HistoryBetUser && this.HistoryBetUser.results
          ? this.HistoryBetUser.results.length
          : 0;
      if (length > 0) {
        totalPage = length % 10;
        if (length <= 10) {
          totalPage = 1;
        } else if (totalPage > 0) {
          totalPage = Math.floor(length / 10) + 1;
        } else {
          totalPage = length / 10;
        }
      }
      return totalPage;
    },

    HistoryUserShow() {
      let arr = [];
      if (
        this.HistoryBetUser &&
        this.HistoryBetUser.results &&
        this.HistoryBetUser.results.length > 0
      ) {
        arr = this.HistoryBetUser.results.slice(
          (this.page - 1) * 10,
          this.page * 10
        );
      }
      return arr;
    },

    refLink() {
      if (this.UserInfo && this.UserInfo.userId) {
        return `${window.location.host}/login?sponsor=${this.UserInfo.userId}`;
      }
      return `${window.location.host}/login?sponsor=`;
    },

    fieldsHistoryUser() {
      return [
        {
          key: 'amountBet',
          sortable: false,
          label: 'Wow',
          class: 'text-center',
        },
        {
          key: 'payout',
          label: this.$t('dashboard.payout'),
          sortable: false,
          class: 'text-center text-capitalize',
        },
        {
          key: 'amountWin',
          sortable: false,
          label: 'Wow ' + this.$t('dashboard.success'),
          class: 'text-center',
        },
        {
          key: 'profit',
          label: this.$t('exchange.profit'),
          class: 'text-center',
        },
        {
          key: 'typeBet',
          label: this.$t('dashboard.typeBet'),
          class: 'text-center',
        },
        {
          key: 'hash',
          sortable: false,
          label: 'Hash',
          class: 'min-200 text-center canWrap',
        },
        {
          key: 'comment',
          label: this.$t('wallet.comment'),
          class: 'text-center bigContent',
        },
        {
          key: 'timeBet',
          label: this.$t('exchange.time'),
          class: 'text-center min-200',
        },
      ];
    },
  },
  methods: {
    onGoToCrashGame() {
      this.$router.push({ name: 'crash' });
    },
    onGoToAffiliate() {
      this.$router.push({ name: 'affiliate' });
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    copyLink() {
      navigator.clipboard.writeText(this.refLink);
    },
    setTab(input) {
      this.page = 1;
      this.$store.commit('socket/SET_CURRENT_TAB', input);
    },
    onCopy: function (e) {
      this.$toastr.s(e.text, this.$t("agency.copy"));
    },
    onError: function (e) {
      this.$toastr.e(e.text, 'Copy Link Referral Failed');
    },
    onChangePageUser(payload) {
      this.page = payload;
    },
  },
  mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
    this.setTab('userBet');
  },
  created() {
    this.$store.dispatch('dashboard/req_getStatistical');
    this.$store.dispatch('member/req_getStatistic');
    this.$store.dispatch('userinfo/req_getUserHistory');
  },
};
</script>

<style lang="scss">
.customer-date-picker.form-control label.form-control.form-control-sm {
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .customer-date-picker.form-control label.form-control.form-control-sm {
    font-size: 11px;
  }

  .b-form-btn-label-control.form-control {
    position: unset;
  }
}

.customer-date-picker.form-control .dropdown-menu .form-control {
  background: #ffffffa8;
}

.dashboard {
  padding: 30px 50px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 30px 10px;
  }

  .sub-title {
    font-size: 16px;
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 5px;
    }

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .title {
    font-size: 30px;
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 20px;

    @media (max-width: 576px) {
      font-size: 22px;
    }
  }

  .direct {
    text-align: right;
    cursor: pointer;
    margin-bottom: 20px;

    p {
      margin-bottom: 0px;
      color: #b0a9a9;
      font-size: 14px;

      @media (max-width: 1400px) {
        font-size: 12px;
      }

      img {
        width: 10px;

        @media (max-width: 576px) {
          width: 8px;
        }
      }
    }
  }

  .dashboard-statistical {
    .statistical-item {
      background: linear-gradient(180deg,
          #081e43 0%,
          rgba(1, 6, 15, 0.63) 100%);
      border-radius: 4px;
      padding: 20px;
      height: 100%;

      @media (max-width: 576px) {
        padding: 10px;
      }

      &.chart {
        @media (max-width: 576px) {
          margin-bottom: 20px;
        }
      }

      &.member {
        @media (max-width: 576px) {
          margin-top: 20px;
        }
      }

      #chart {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (max-width: 576px) {
          margin-bottom: 30px;
        }
      }

      .statistic-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 576px) {
          flex-direction: row;
          justify-content: space-around;
        }

        p {
          font-size: 22px;
          margin-bottom: 30px;

          @media (max-width: 1400px) {
            font-size: 16px;
          }

          @media (max-width: 576px) {
            margin-bottom: 0px;
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 0px;
          }

          &.statistic-info-percent {
            background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: bold;
            font-size: 30px;

            @media (max-width: 1400px) {
              font-size: 26px;
            }

            @media (max-width: 576px) {
              font-size: 20px;
            }
          }
        }

        .line {
          @media (max-width: 576px) {
            width: 2px;
            height: 100%;
            background: rgba(93, 184, 251, 0.42);
          }
        }

        hr {
          width: 80%;
          border: 1px solid rgba(93, 184, 251, 0.42);
          margin-top: 0px;
          margin-bottom: 30px;

          @media (max-width: 576px) {
            display: none;
          }
        }
      }

      .img-item-info {
        @media (max-width: 576px) {
          width: 30px;
          margin-right: 0px;
        }
      }

      .statistical-item-info {
        margin-left: 20px;

        p {
          color: #a5a5a5;
          font-size: 16px;
          margin-bottom: 5px;

          @media (max-width: 992px) {
            font-size: 14px;
          }

          &.amount {
            color: #ffffff;
            font-size: 25px;
            font-weight: bold;

            @media (max-width: 992px) {
              font-size: 20px;
            }

            @media (max-width: 576px) {
              font-size: 16px;
            }
          }
        }
      }

      .statistical-member {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 576px) {
          flex-direction: column;
        }

        .statistical-member-item {
          width: 48%;

          @media (max-width: 1400px) {
            &:nth-child(1) {
              width: 58%;
            }

            &:nth-child(2) {
              width: 38%;
            }
          }

          @media (max-width: 576px) {
            &:nth-child(1) {
              width: 100%;
            }

            &:nth-child(2) {
              width: 100%;
              margin-top: 20px;
            }
          }

          .box-member {
            h3 {
              color: #bebebe;
              font-size: 18px;
              margin-bottom: 15px;

              @media (max-width: 992px) {
                font-size: 16px;
              }
            }

            hr {
              width: 100%;
              border: 1px solid #404040;
              margin-top: 0px;
              margin-bottom: 15px;

              @media (max-width: 992px) {
                display: none;
              }
            }

            p {
              color: #ffffff;
              margin-bottom: 15px;
              margin-top: 0px;
              line-height: 100%;

              @media (max-width: 992px) {
                font-size: 14px;
              }

              .amount {
                background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-size: 22px;
                font-weight: bold;

                @media (max-width: 992px) {
                  font-size: 18px;
                }
              }

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }

          .box-qr {
            img {
              width: 150px;

              @media (max-width: 1600px) {
                width: 100px;
              }

              @media (max-width: 1200px) {
                width: 80px;
              }
            }
          }

          .reflink {
            h3 {
              font-size: 20px;
              background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 0px;
            }

            input {
              margin: 15px 0px;

              @media (max-width: 576px) {
                font-size: 14px;
              }
            }

            .copy-ref {
              width: 100%;
              height: 50px;
              background: linear-gradient(91.46deg,
                  #0ec5ff 1.08%,
                  #3964fb 83%) !important;
              border-radius: 3px;
              position: relative;
              z-index: 1;
              font-weight: bold;
              font-size: 20px;

              @media (max-width: 576px) {
                font-size: 16px;
              }

              &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 50px;
                background: linear-gradient(91.46deg,
                    #0ec5ff 1.08%,
                    #3964fb 83%);
                filter: blur(21px);
                z-index: -1;
              }
            }
          }
        }
      }
    }
  }

  .dashboard-history {
    margin-top: 50px;

    .dashboard-history-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 440px) {
        flex-direction: column;
      }

      .group-search {
        display: flex;
        align-items: center;

        @media (max-width: 440px) {
          margin-top: 20px;
        }

        .custom-input {
          width: 200px;
          height: 40px;

          @media (max-width: 992px) {
            font-size: 12px !important;
            width: 140px;
            height: 30px;
          }
        }
      }
    }

    .historyTable {
      background: linear-gradient(180deg,
          #081e43 0%,
          rgba(1, 6, 15, 0.63) 100%);
      border-radius: 4px;
      padding: 20px;
    }

    .table-mobile {
      width: 100%;
      background: linear-gradient(180deg,
          #081e43 0%,
          rgba(1, 6, 15, 0.63) 100%);
      border-radius: 4px;
      padding: 20px;

      .header {
        width: 100%;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 6px;
        padding: 10px;
        margin-bottom: 20px;
      }

      .collapse {
        margin-top: -20px !important;
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(34px);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        margin-bottom: 20px;

        P {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.tabs-wrapper {
  display: inline-flex;
  margin-bottom: 40px;

  .botan {
    padding: 10px 30px;
  }
}

.currency-icon {
  width: 25px;
  object-fit: contain;
}
</style>
