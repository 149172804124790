<template>
    <div class="transaction-bottom-paginate">
        <p>Total {{ totalPage }}</p>
        <b-input type="text" v-model="currentPage" readonly></b-input>
        <b-button
            class="btnNoStyle"
            @click="onChangePage('minus')"
            :disabled="currentPage === 1"
        >
            <img src="~@/assets/images/icons/icon-prev-white.png" alt="" />
        </b-button>
        <b-button
            class="btnNoStyle"
            @click="onChangePage('plus')"
            :disabled="currentPage === totalPage"
        >
            <img src="~@/assets/images/icons/icon-next-white.png" alt="" />
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'PaginatePageView',
    props: {
        totalPage: {
            Type: Number,
            default: 1,
        },
        perPage: {
            Type: Number,
            default: 1,
        },
    },
    data() {
        return {
            currentPage: this.perPage,
        };
    },
    methods: {
        onChangePage(type) {
            if (type === 'plus') {
                this.currentPage += 1;
                if (this.currentPage > this.totalPage) {
                    this.currentPage = 1;
                }
            }
            if (type === 'minus') {
                this.currentPage -= 1;
                if (this.currentPage === 0) {
                    this.currentPage = this.totalPage;
                }
            }
            this.$emit('current', this.currentPage);
        },
    },
};
</script>

<style lang="scss" scoped>
.transaction-bottom-paginate {
    display: flex;
    align-items: center;
    margin-top: 30px;
    p {
        margin-bottom: 0px;
        margin-right: 20px;
        color: #d4dff4;
    }
    input {
        width: 50px;
        height: 30px;
        background: #495A88;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        border: 0;
        color: #ffffff;
        text-align: center;
        margin-right: 20px;
    }
    button {
        background: #495A88 !important;
        border-radius: 5px !important;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(3) {
            margin-right: 3px;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
}
</style>
